import React from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import Layout from "../components/Layout";
import PageHeader from "../components/PageHeader";
import ImageMosaic from "../components/ImageMosaic";

const OfficesPage = ({ data }) => {
  const remark = data.allMarkdownRemark.edges[0].node;
  const { frontmatter: pageData } = remark;

  const toimitilaMosaicImages = () => {
    const cols = [2, 2, 1, 3];
    let temp = [];

    pageData.images.slice(0, 4).forEach((image, index) => {
      let entry = {
        img: image,
        cols: cols[index],
      };
      temp.push(entry);
    });

    return temp;
  };
  return (
    <Layout title="Toimitilat">
      <div className="page section">
        <div className="container offices-page">
          <PageHeader
            title={pageData.pageTitle}
            description={pageData.pageDescription}
            image={pageData.pageImage}
          />
          <ImageMosaic images={toimitilaMosaicImages()} />
          <div className="offices-page__break">
            <img src={pageData.images[pageData.images.length - 1]} />
          </div>
        </div>
      </div>
    </Layout>
  );
};

OfficesPage.propTypes = {
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      edges: PropTypes.array,
    }),
  }),
};

export default OfficesPage;

export const pageQuery = graphql`
  query OfficesPageQuery {
    allMarkdownRemark(
      filter: { frontmatter: { templateKey: { eq: "offices-page" } } }
    ) {
      edges {
        node {
          frontmatter {
            templateKey
            pageTitle
            pageDescription
            pageImage
            images
          }
        }
      }
    }
  }
`;
